<template>
  <div class="app-container app-qa fixed v">
    <div class="head-container bc-l">
      <el-radio-group class="filter-item" v-model="query.questionType" @change="toQuery">
        <el-radio-button label>全部</el-radio-button>
        <el-radio-button label="mine">我的提问</el-radio-button>
        <el-radio-button v-for="(t, k) in questionTypes" :key="k" :label="k">{{t}}</el-radio-button>
      </el-radio-group>
      <el-input v-model="query.title" class="filter-item" :maxlength="20" placeholder="根据问题标题搜索" @keypress.enter.native="toQuery" @clear="toQuery" clearable style="width: 220px;" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery" :loading="loading">搜索</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="editQuestion(null)" v-if="checkPermission(['ONLINE_INQUIRY_ADD'])">我要提问</el-button>
    </div>
    <div class="flex qas" @click="handleQAClick" style="overflow: hidden auto;">
      <template v-for="d in data">
        <div :key="d.id" class="padding-20 q-item">
          <div class="h s">
            <div class="icon-q no-flex">Q</div>
            <div class="flex lh-150">
              <div class="bold">{{d.title}}</div>
              <div class="fs-mini fc-g">{{ new Date(d.createAt).format('yyyy/MM/dd hh:mm')}}，{{d.createEntName}} -- {{d.createBy}}</div>
              <div class="q-content" v-html="d.question"></div>

              <div class="gap-1x" v-if="!d.isAnswered">
                <el-button type="primary" size="mini" icon="el-icon-edit" @click="editQuestion(d)" v-if="checkPermission(['ONLINE_INQUIRY_ADD'])">修改问题</el-button>
                <el-button type="danger" size="mini" icon="el-icon-delete" @click="delQuestion(d)" v-if="checkPermission(['ONLINE_INQUIRY_DEL'])">删除问题</el-button>
              </div>
            </div>
          </div>
          <div class="h s" style="margin-top: 20px;">
            <div class="icon-q icon-a no-flex">A</div>
            <div class="flex lh-150">
              <template v-if="d.isAnswered">
                <div class="q-content" v-html="d.answer"></div>
                <div class="fs-mini fc-g">{{ new Date(d.answerAt).format('yyyy/MM/dd hh:mm')}}，{{d.answerEntName}} -- {{d.answerBy}}</div>
              </template>
              <div class="fc-g" v-else>还没有人回答该问题。</div>
              <div class="gap-1x" v-if="checkPermission(['ONLINE_INQUIRY_ANSWER'])">
                <el-button type="primary" size="mini" icon="el-icon-chat-dot-square" @click="editAnswer(d)">{{d.isAnswered ? "编辑回答" : "我来回答"}}</el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="v c" v-if="!data || !data.length">
        <div class="padding-20 ta-c fc-g">没有找到任何相关的问题，请更改查询条件后再重新尝试。</div>
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="editQuestion(null)">我要提问</el-button>
      </div>
    </div>
    <div class="no-flex qa-pager">
      <el-pagination :total="total" :current-page="page + 1" layout="prev, pager, next, ->, total" hide-on-single-page @size-change="sizeChange" @current-change="pageChange" />
    </div>

    <el-dialog title="编辑问题" :visible.sync="question.editing" append-to-body :close-on-click-modal="false" width="800px" @closed="question.form = null">
      <el-form ref="qForm" :model="question.form" :rules="question.rules" label-position="right" label-width="86px" label-suffix=":" v-if="question.form">
        <el-form-item prop="type" label="问题类型">
          <el-select v-model="question.form.questionType" placeholder="选择提问类型">
            <el-option v-for="(t, k) in questionTypes" :key="k" :label="t" :value="k" />
          </el-select>
        </el-form-item>
        <el-form-item prop="title" label="标题">
          <el-input v-model.trim="question.form.title" maxlength="50" />
        </el-form-item>
        <el-form-item label="内容">
          <html-editor v-model="question.form.question" :height="320" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="question.editing = false">取消</el-button>
        <el-button :loading="question.saving" type="primary" @click="doSaveQuestion">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑回答" :visible.sync="answer.editing" append-to-body :close-on-click-modal="false" width="800px" @closed="answer.form = null">
      <el-form ref="aForm" :model="answer.form" :rules="answer.rules" label-position="right" label-width="88px" label-suffix=":" v-if="answer.form">
        <el-form-item label="所有人可见">
          <el-switch v-model="answer.form.visible" />
        </el-form-item>
        <el-form-item label="回答内容">
          <html-editor v-model="answer.form.answer" :height="320" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="answer.editing = false">取消</el-button>
        <el-button :loading="answer.saving" type="primary" @click="doSaveAnswer">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import HtmlEditor from "@/views/components/common/htmlEditor";
import request from "@/utils/request";

export default {
  components: { HtmlEditor },
  mixins: [initData],
  data() {
    return {
      query: {
        questionType: "",
        title: "",
      },
      questionTypes: {
        purchase: "采购下单",
        retail: "门店销售",
        payment: "在线付款",
        stock: "库房管理",
        other: "其他",
      },
      question: {
        editing: false,
        saving: false,
        form: null,
        rules: {
          title: [{ required: true, message: "请填写问题标题" }],
          question: [{ required: true, message: "请填写问题内容" }],
        },
      },
      answer: {
        editing: false,
        saving: false,
        form: null,
        rules: {
          answer: [{ required: true, message: "请填写回答题内容" }],
        },
      },
    };
  },
  mounted() {
    this.toQuery();
  },
  methods: {
    checkPermission,
    beforeInit() {
      let params = Object.assign({}, this.query);
      if (params.questionType === "mine") {
        this.url = "api/question/mine";
        delete params.questionType;
      } else {
        this.url = "api/question";
      }
      params.sort = "createAt,desc";
      this.params = params;
      return true;
    },

    handleQAClick(e) {
      if (e.target instanceof Image) {
        window.appRoot.openPreview({
          image: true,
          video: false,
          url: e.target.src,
        });
      }
    },

    editQuestion(q) {
      this.question.form = q
        ? JSON.parse(JSON.stringify(q))
        : {
            questionType: "purchase",
            title: null,
            question: null,
            isAnswered: false,
          };

      this.question.editing = true;
    },
    doSaveQuestion() {
      this.$refs.qForm &&
        this.$refs.qForm.validate().then(() => {
          this.question.saving = true;
          request({
            url: "api/question",
            method: this.question.form.id ? "put" : "post",
            data: this.question.form,
          })
            .then((res) => {
              if (this.question.form.id) {
                this.$message.success("提问修改成功，请耐心等候回复");
                this.init();
              } else {
                this.$message.success("提问成功，请耐心等候回复");
                this.query.questionType = "mine";
                this.toQuery();
              }
              this.question.editing = false;
            })
            .then(() => {
              this.question.saving = false;
            });
        });
    },
    delQuestion(q) {
      this.$confirm("确定要删除该问题吗？", "操作确认", {
        type: "warning",
      }).then(() => {
        request({
          url: "api/question/" + q.id,
          method: "delete",
        }).then((res) => {
          this.$message.success("删除修改成功");
          this.init();
        });
      });
    },

    editAnswer(d) {
      this.answer.form = d.isAnswered
        ? {
            id: d.id,
            answer: d.answer,
            visible: d.visible,
          }
        : {
            id: d.id,
            answer: "",
            visible: true,
          };
      this.answer.editing = true;
    },
    doSaveAnswer() {
      this.$refs.aForm &&
        this.$refs.aForm.validate().then(() => {
          this.answer.saving = true;
          request({
            url: "api/answer",
            method: "put",
            data: this.answer.form,
          })
            .then((res) => {
              this.$message.success("回答问题成功");
              this.init();
              this.answer.editing = false;
            })
            .then(() => {
              this.answer.saving = false;
            });
        });
    },
  },
};
</script>

<style lang="less">
.app-qa {
  background-color: #e4e7ed;

  .icon-q {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    background-color: #f56c6c;
    color: white;
    font-size: 18px;
    margin-right: 10px;

    &.icon-a {
      background-color: #67c23a;
    }
  }

  .qa-pager {
    padding: 10px 17px 10px 0;
    > .el-pagination {
      width: 60%;
      min-width: 800px;
      margin: 0 auto;
      padding: 0;
    }
  }

  .q-content {
    img {
      max-width: 100%;
    }
  }

  .q-item {
    margin: 10px auto;
    width: 60%;
    min-width: 800px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
  }

  .q-title {
    line-height: 1.5;
  }
}
</style>